<template>
  <div>
    <div
      class="text-h6 px-2 mb-2"
      >
      Preguntas
    </div>
    <template
      v-for="i in 4"
      >
      <question
        class="mb-2"
        ></question>

    </template>
    <v-textarea
      label="Tu pregunta"
      placeholder="¡No seas tímido, deja tu consulta!" 
      class="mt-4"
      outlined
      hide-details
      rows="3"
      ></v-textarea>
  </div>
</template>

<script>
import Question from './Question'

export default {
  components: {
    Question
  }
}
</script>
